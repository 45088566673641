import { jsx as _jsx } from "react/jsx-runtime";
import { ROUTES } from "../constants";
import React from "react";
var CompanyPage = React.lazy(function () { return import("../pages/CompanyPage/CompanyPage"); });
var CompaniesSearchPage = React.lazy(function () { return import("../pages/CompaniesSearchPage/CompaniesSearchRage/companiesSearchRage"); });
var AdminModeratorPage = React.lazy(function () { return import("../pages/AdminModeratePage/AdminModeratePage"); });
var AdminProfilePage = React.lazy(function () { return import("../pages/AdminProfilePage/AdminProfilePage"); });
var CompanyProfilePage = React.lazy(function () { return import("../pages/CompanyProfilePage/CompanyProfilePage"); });
var ExpertProfilePage = React.lazy(function () { return import("../pages/ExpertProfilePage/ExpertProfilePage"); });
var OpenArticlesPage = React.lazy(function () { return import("../pages/OpenArticlesPage/openedArticlesPage"); });
var OpenEventsPage = React.lazy(function () { return import("../pages/OpenEventsPage/openedEventsPage"); });
var OpenNewsPage = React.lazy(function () { return import("../pages/OpenNewsPage/openedNewsPage"); });
var UserProfilePage = React.lazy(function () { return import("../pages/UserProfilePage/UserProfilePage"); });
var CreateVacanciesPage = React.lazy(function () { return import("../pages/VacancyPage/CreateVacanciesPage/CreateVacanciesPage"); });
var RefreshVacancyByIdPage = React.lazy(function () { return import("../pages/VacancyPage/RefreshVacancyById/RefreshVacancyById"); });
var VacancyById = React.lazy(function () { return import("../pages/VacancyPage/VacancyById/VacancyById"); });
var CreatePostDraftEditor = React.lazy(function () { return import("../pages/CreatePost/CreatePost"); });
var AboutUsPage = React.lazy(function () { return import("../pages/AboutUsPage/AboutUsPage"); });
// const EditPublicationPage = React.lazy(
//   () => import("../pages/AdminModeratePage/EditPublicationPage/EditPublicationPage"),
// );
var ContainerArticlePage = React.lazy(function () { return import("../pages/ArticlesPage/ContainerArticlePage"); });
var AuthSocialPage = React.lazy(function () { return import("../pages/AuthSocialPage/authSocialPage"); });
var CompanyByIdPage = React.lazy(function () { return import("../pages/CompanyByIdPage/CompanyByIdPage"); });
var EmailConfirmation = React.lazy(function () { return import("../pages/EmailConfirmation/EmailConfirmation"); });
var ContainerEvents = React.lazy(function () { return import("../pages/Events/ContainerEvents"); });
var ExpertByIdPage = React.lazy(function () { return import("../pages/ExpertByIdPage/ExpertByIdPage"); });
var ContainerHomePage = React.lazy(function () { return import("../pages/HomePage/ContainerHomePage"); });
var NotFoundPage = React.lazy(function () { return import("../pages/NotFoundPage/NotFoundPage"); });
var NotificationsPageReactQuery = React.lazy(function () { return import("../pages/NotificationsPageReactQuery/NotificationsPageReactQuery"); });
var UserByIdPage = React.lazy(function () { return import("../pages/UserByIdPage/UserByIdPage"); });
var ResetPasswordNewComponent = React.lazy(function () { return import("../pages/ResetPasswordPage/ResetPasswordNewComp"); });
var SearchResultPage = React.lazy(function () { return import("../pages/SearchResultPage/SearchResultPage"); });
var VacancyPage = React.lazy(function () { return import("../pages/VacancyPage/Vacancy"); });
export var PUBLIC_ROUTES = [
    {
        Component: _jsx(ContainerHomePage, {}),
        isIndexElem: true,
    },
    {
        path: ROUTES.news,
        Component: _jsx(ContainerHomePage, {}),
    },
    {
        path: ROUTES.events,
        Component: _jsx(ContainerEvents, {}),
    },
    {
        path: ROUTES.articles,
        Component: _jsx(ContainerArticlePage, {}),
    },
    {
        path: ROUTES.vacancy,
        Component: _jsx(VacancyPage, {}),
    },
    {
        path: ROUTES.open_news_page,
        Component: _jsx(OpenNewsPage, {}),
    },
    {
        path: ROUTES.open_events_page,
        Component: _jsx(OpenEventsPage, {}),
    },
    {
        path: ROUTES.open_articles_page,
        Component: _jsx(OpenArticlesPage, {}),
    },
    {
        path: ROUTES.search_result,
        Component: _jsx(SearchResultPage, {}),
    },
    {
        path: ROUTES.companies_page,
        Component: _jsx(CompanyPage, {}),
    },
    {
        path: ROUTES.companies_serch_page,
        Component: _jsx(CompaniesSearchPage, {}),
    },
    {
        path: ROUTES.companyProfile,
        Component: _jsx(CompanyByIdPage, {}),
    },
    {
        path: ROUTES.userProfile,
        Component: _jsx(UserByIdPage, {}),
    },
    {
        path: ROUTES.expertProfile,
        Component: _jsx(ExpertByIdPage, {}),
    },
    {
        path: ROUTES.about,
        Component: _jsx(AboutUsPage, {}),
    },
    {
        path: ROUTES.resetPasswordPage,
        Component: _jsx(ResetPasswordNewComponent, {}),
    },
    {
        path: ROUTES.AuthSocial,
        Component: _jsx(AuthSocialPage, {}),
    },
    {
        path: ROUTES.emailConfirmation,
        Component: _jsx(EmailConfirmation, {}),
    },
    {
        path: ROUTES.vacancy_id,
        Component: _jsx(VacancyById, {}),
    },
    {
        path: ROUTES.notFoundPage,
        Component: _jsx(NotFoundPage, {}),
    },
];
export var AUTH_ROUTES = [
    {
        path: ROUTES.create_vacancy,
        Component: _jsx(CreateVacanciesPage, {}),
    },
    {
        path: ROUTES.vacancy_refresh,
        Component: _jsx(RefreshVacancyByIdPage, {}),
    },
    {
        path: ROUTES.create,
        Component: _jsx(CreatePostDraftEditor, {}),
    },
    {
        path: ROUTES.notifications,
        Component: _jsx(NotificationsPageReactQuery, {}),
    },
    {
        path: ROUTES.refreshMaterial,
        Component: _jsx(CreatePostDraftEditor, {}),
    },
];
export var USER_ROUTES = [
    {
        path: ROUTES.user_profile,
        Component: _jsx(UserProfilePage, {}),
    },
];
export var EXPERT_ROUTES = [
    {
        path: ROUTES.expert_profile,
        Component: _jsx(ExpertProfilePage, {}),
    },
];
export var COMPANY_ROUTES = [
    {
        path: ROUTES.company_profile,
        Component: _jsx(CompanyProfilePage, {}),
    },
];
export var ADMIN_ROUTES = [
    {
        path: ROUTES.admin,
        Component: _jsx(AdminProfilePage, {}),
    },
    {
        path: ROUTES.test_moder,
        Component: _jsx(AdminModeratorPage, {}),
    },
    {
        path: ROUTES.test_moder_id,
        Component: _jsx(CreatePostDraftEditor, {}),
    },
];
