var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Portal } from "../Portal/Portal";
import style from "./popup.module.css";
export var PopupWithoutOverlay = function (_a) {
    var children = _a.children, onClose = _a.onClose, isOpened = _a.isOpened;
    if (!isOpened)
        return null;
    return (_jsx(Portal, { children: _jsxs("div", __assign({ className: style.container, role: "dialog" }, { children: [_jsx("div", { className: style.overlay, role: "button", onClick: function () { return onClose(); } }), _jsx("div", __assign({ className: style.content }, { children: children }))] })) }));
};
