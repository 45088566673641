// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tmkAPiNg9zLK1uFqhdFS {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 36px;
  box-sizing: border-box;

  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.v0fYXgcZoBEZMBm5ya2e {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal-blur-wrapper);
  backdrop-filter: blur(3px);

  cursor: pointer;
}

/* for css transition animations */
.r4LYK14E5jQiwFJEHQwA {
  opacity: 0;
  transform: scale(0.9);
}

.SgYdzc0vGlMgQKwmHUf8 {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.g0z3ZYs2VpiRQtxLloJI {
  opacity: 1;
}

.hOFsv1Iw9oSJh13Jws9w {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* for css transition animations */`, "",{"version":3,"sources":["webpack://./src/UIKit/PopupWithOverlay/popup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;;EAET,aAAa;EACb,sBAAsB;;EAEtB,YAAY;;EAEZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,2CAA2C;EAC3C,0BAA0B;;EAE1B,eAAe;AACjB;;AAEA,kCAAkC;AAClC;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,wBAAwB;EACxB,0CAA0C;AAC5C;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,0CAA0C;AAC5C;;AAEA,kCAAkC","sourcesContent":[".container {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n\r\n  padding: 36px;\r\n  box-sizing: border-box;\r\n\r\n  z-index: 999;\r\n\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.overlay {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: var(--modal-blur-wrapper);\r\n  backdrop-filter: blur(3px);\r\n\r\n  cursor: pointer;\r\n}\r\n\r\n/* for css transition animations */\r\n.alert-enter {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n}\r\n\r\n.alert-enter-active {\r\n  opacity: 1;\r\n  transform: translateX(0);\r\n  transition: opacity 300ms, transform 300ms;\r\n}\r\n\r\n.alert-exit {\r\n  opacity: 1;\r\n}\r\n\r\n.alert-exit-active {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n  transition: opacity 300ms, transform 300ms;\r\n}\r\n\r\n/* for css transition animations */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tmkAPiNg9zLK1uFqhdFS`,
	"overlay": `v0fYXgcZoBEZMBm5ya2e`,
	"alert-enter": `r4LYK14E5jQiwFJEHQwA`,
	"alert-enter-active": `SgYdzc0vGlMgQKwmHUf8`,
	"alert-exit": `g0z3ZYs2VpiRQtxLloJI`,
	"alert-exit-active": `hOFsv1Iw9oSJh13Jws9w`
};
export default ___CSS_LOADER_EXPORT___;
